import JobRequestForm from './jobrequestform.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={require("./dsiglogo.png")} width ="50%" alt="Delta Sigma Phi Logo"></img>
      <header className="App-header">
          <div className="styledDiv"> 
            <JobRequestForm />   
          </div>  
          <h1 style={{color: "white"}}>
          {getRandomLineFromFile("./flavorText.txt")}
          </h1>
      </header>
    </div>
  );
}

function getRandomLineFromFile(filename) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", filename, false);
  xhr.send();
  const lines = xhr.responseText.split("\n");
  const randomIndex = Math.floor(Math.random() * lines.length);
  const randomLine = lines[randomIndex];
  return `${randomLine}`;
}

export default App;
