// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCDjX9h3Zin1cBV3rQJWvW2D-e-78zDwSQ",
  authDomain: "dsigjobrequests.firebaseapp.com",
  projectId: "dsigjobrequests",
  storageBucket: "dsigjobrequests.appspot.com",
  messagingSenderId: "374954475577",
  appId: "1:374954475577:web:b01bd2ad040d6504c84de8",
  ignoreUndefinedProperties: true
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);


export default db;