import React from "react";
import { doc, setDoc } from 'firebase/firestore';
import db from './firebase';
import { uuidv4 } from "@firebase/util";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { collection, getDocs, getDoc, updateDoc } from "firebase/firestore";

class JobRequestForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {value: 'NoJobSelected', timeForRequest: new Date(), currentDateTime: new Date(), isLoading: false};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }

    handleTimeChange(date) { //Deprecated
        this.setState({timeForRequest: date});
        return true;
    } 

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        this.setState({isLoading: true});
        this.pushData();
        this.setState({isLoading: false});
        event.preventDefault();
    }

    async handleDataRequest(userName, contentText, email, uuidCode) {
        try {
        //Check if the document has already been uploaded
        const querySnapshot = await getDocs(collection(db, "messages"));
        let docExists = false;
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if(doc.data().content.text === contentText) {
            console.log(doc.data().content.text);
            console.log(contentText);
            docExists = true;
            alert("Error! Message has already been sent!");
            return false;
            }
        });

        if(docExists) {
            return false;
        }

        //Creates a new user if they don't exist
        await setDoc(doc(db, "users", userName), {
            userName: userName,
            email: email,
        }, { merge: true });

        //Finds the user and increments their number of requests
        let userRef = doc(db, "users", userName);
        let userDoc = await getDoc(userRef);
        let userNumOfRequests = userDoc.data().numOfRequests;

        if (typeof userNumOfRequests === 'undefined' || isNaN(userNumOfRequests)) {
        userNumOfRequests = 1;
        } else {
        userNumOfRequests = parseInt(userNumOfRequests) + 1;
        }

        //Update the number of requests
        await updateDoc(userRef, {
            numOfRequests: userNumOfRequests,
        })
        return true;
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }


     myFunc(){
        let id = document.getElementById("selection").value,
            blocks = document.querySelectorAll('.block');

        for (let block of blocks) {
            block.style.display = 'none';
        }

        if ('default' !== id) {
            document.getElementById(id).style.display = 'block';    
            if(id === "BootsAndJeansCheck") {
                document.getElementById("pledge").style = "display: none";
            }  else {
                document.getElementById("pledge").style = "display: block";
            }

        }
    };

    getNumber(userName) {
        switch (userName) {
            default:
                return '-1001845994475';
            case 'any':
                return '-1001845994475';
        }
    }

    formatTime(date) { //Deprecated
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }

    async confirmUpload(uuid) {
        const querySnapshot = await getDocs(collection(db, "messages"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
                if(doc.data().content.uuid === uuid) {
                alert('Request Sent!\nConfirmation Code: ' + uuid);
                return true;
            }
        });
        alert("Error! Message has not been sent! Your internet connection may not be stable. Error Code: 414");
        return false;
    }
      
    async pushData() {
        let id = document.getElementById("selection").value;
        let userName = document.getElementById("name").value;
        let timeForRequest = this.state.timeForRequest;
        let prefferedPledge = document.getElementById("pledge").value;
        let email = document.getElementById("email").value;
        let uuidCode = uuidv4();

        let checkTime = new Date();

        if(timeForRequest.getHours() <= checkTime.getHours() && timeForRequest.getMinutes() <= checkTime.getMinutes()) {
            timeForRequest = "ASAP";
        } else {
            timeForRequest = this.formatTime(timeForRequest);
        }

        if(prefferedPledge === 'default') {
            alert("Invalid Request!\nYou must pick a pledge or pick any pledge!");
            return;
        }
        if(id === 'default') {
            alert("Invalid Request!\nYou must select a job!");
            return;
        }
        if(userName == null) {
            alert("Invalid Request!\nYou must enter your name!");
            return;
        }
        if(email == null) {
            alert("Invalid Request!\nYou must enter your email!");
            return;
        }

        switch (id) {
            default:
                alert("An unknown error has occured. Error ref: 410")
                break;
            case "GiveRide":
                try {              
                    let pickupLocation = document.getElementById('RidePickupLocation').value
                    let dropoffLocation = document.getElementById('RideDropoffLocation').value
                    let otherDetails = document.getElementById('RideOtherDetails').value
                    let numberOfPeople = document.getElementById('RideNumOfPeople').value
                    let contentText;
                    let sendTo;
                    if(prefferedPledge === 'any') {
                    contentText = `Job Recieved! (Any Pledge Available)\n${userName} has requested a ride from ${pickupLocation} to ${dropoffLocation}. You will be giving a ride to ${numberOfPeople} people. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                    sendTo = this.getNumber('any')
                    } else {
                    contentText = `Job Recieved! (You've been selected!)\n${userName} has requested a ride from ${pickupLocation} to ${dropoffLocation}. You will be giving a ride to ${numberOfPeople} people. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                    sendTo = this.getNumber(document.getElementById("pledge").value)
                    }

                    if(await this.handleDataRequest(userName, contentText, email, uuidCode)) {

                    await setDoc(doc(db, "messages", uuidCode), {
                        userName: userName,
                        numberOfPeople: numberOfPeople,
                        pickupLocation: pickupLocation,
                        dropoffLocation: dropoffLocation,
                        otherdetails: otherDetails,
                        channelId: '3c046d048582439aa6ec8ec9cd834b48',
                        type: 'text',
                        content: {
                            text: contentText
                        },                           
                        to: sendTo,
                    });
                    await(this.confirmUpload(uuidCode));
                    } else {
                        alert("An error has occured! Error Code: 411");
                    }

                } catch (e) {
                    alert(e);
                }
                break;
            case "BringFood":
                try {
                    let nameOfResturant = document.getElementById('FoodResturant').value
                    let order = document.getElementById('FoodOrder').value
                    let dropoffLocation = document.getElementById('FoodDropoffLocation').value
                    let otherDetails = document.getElementById('FoodOtherDetails').value
                    let contentText;
                    let sendTo;
                    if(prefferedPledge === 'any') {
                    contentText = `Job Recieved! (Any Pledge Available)\n${userName} has requested food from ${nameOfResturant} to ${dropoffLocation}. They want ${order}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                    sendTo = this.getNumber('any')
                    } else {
                    contentText = `Job Recieved! (You've been selected!)\n${userName} has requested food from ${nameOfResturant} to ${dropoffLocation}. They want ${order}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                    sendTo = this.getNumber(document.getElementById("pledge").value)
                    }

                    if(await this.handleDataRequest(userName, contentText, email, uuidCode)) {
                    await setDoc(doc(db, "messages", uuidCode), {
                        userName: userName,
                        nameOfResturant: nameOfResturant,
                        order: order,
                        dropoffLocation: dropoffLocation,
                        otherdetails: otherDetails,
                        channelId: '3c046d048582439aa6ec8ec9cd834b48',
                        type: 'text',
                        content: {
                            text: contentText
                        },                           
                        to: sendTo,
                    })
                    await(this.confirmUpload(uuidCode));
                    } else {
                        alert("An error has occured! Error Code: 412");
                    }
                } catch (e) {
                    alert(e);
                }
                break;
                case "BringDrinks":
                try {
                    let nameOfStore = document.getElementById('DrinkStore').value
                    let order = document.getElementById('DrinkOrder').value
                    let dropoffLocation = document.getElementById('DrinkDropoffLocation').value
                    let otherDetails = document.getElementById('DrinkOtherDetails').value
                    let contentText;
                    let sendTo;
                    if(prefferedPledge === 'any') {
                    contentText = `Job Recieved! (Any Pledge Available)\n${userName} has requested drinks from ${nameOfStore} to ${dropoffLocation}. They want ${order}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                    sendTo = this.getNumber('any')
                    } else {
                    contentText = `Job Recieved! (You've been selected!)\n${userName} has requested drinks from ${nameOfStore} to ${dropoffLocation}. They want ${order}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                    sendTo = this.getNumber(document.getElementById("pledge").value)
                    }

                    if(await this.handleDataRequest(userName, contentText, email, uuidCode)) {
                    await setDoc(doc(db, "messages", uuidCode), {
                        userName: userName,
                        nameOfResturant: nameOfStore,
                        order: order,
                        dropoffLocation: dropoffLocation,
                        otherdetails: otherDetails,
                        channelId: '3c046d048582439aa6ec8ec9cd834b48',
                        type: 'text',
                        content: {
                            text: contentText
                        },                           
                        to: sendTo,
                    })
                    await(this.confirmUpload(uuidCode));
                    } else {
                        alert("An error has occured! Error Code: 412");
                    }
                } catch (e) {
                    alert(e);
                }
                break;
            case "WashCar":
                try {
                    let location = document.getElementById('WashLocationOfCar').value
                    let otherDetails = document.getElementById('WashOtherDetails').value
                    
                    if(prefferedPledge === 'any') {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (Any Pledge Available)\n${userName} has requested their car be washed at ${location}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },                           
                            to: this.getNumber('any'),
                        })
                        await(this.confirmUpload(uuidCode));
                    } else {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (You've been Requested!)\n${userName} has requested their car be washed at ${location}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },     
                            to: this.getNumber(document.getElementById("pledge").value),
                        })
                        await(this.confirmUpload(uuidCode));
                    }
                } catch (e) {
                    alert(e);
                }
                break;
            case "CookFood":
                try {
                    let location = document.getElementById('CookLocationOfFood').value
                    let otherDetails = document.getElementById('CookOtherDetails').value

                    if(prefferedPledge === 'any') {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (Any Pledge Available)\n${userName} has requested their food be cooked at ${location}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },                           
                            to: this.getNumber('any'),
                        })
                        await(this.confirmUpload(uuidCode));
                    } else {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (You've been Requested!)\n${userName} has requested their food be cooked at ${location}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },     
                            to: this.getNumber(document.getElementById("pledge").value),
                        })
                        await(this.confirmUpload(uuidCode));
                    }
                } catch (e) {
                    alert(e);
                }
                break; 
            case "CleanHouse":
                try {
                    let location = document.getElementById('CleanLocation').value
                    let numOfPeople = document.getElementById('CleanNumOfPledges').value
                    let otherDetails = document.getElementById('CleanOtherDetails').value
                    
                    if(prefferedPledge === 'any') {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            numberOfPeople: numOfPeople,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (Any Pledge Available)\n${userName} has requested their house be cleaned at ${location}. They would like ${numOfPeople}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },                           
                            to: this.getNumber('any'),
                        })
                        await(this.confirmUpload(uuidCode));
                    } else {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            numberOfPeople: numOfPeople,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (You've been Requested!)\n${userName} has requested their house be cleaned at ${location}. They would like ${numOfPeople}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },     
                            to: this.getNumber(document.getElementById("pledge").value),
                        })
                        await(this.confirmUpload(uuidCode));
                    }
                } catch (e) {
                    alert(e);
                }
                break;    
            case "Yardwork":
                try {
                    let location = document.getElementById('YardLocation').value
                    let numOfPeople = document.getElementById('YardNumOfPledges').value
                    let otherDetails = document.getElementById('YardOtherDetails').value
                    
                    if(prefferedPledge === 'any') {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            numberOfPeople: numOfPeople,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (Any Pledge Available)\n${userName} has requested yardwork be done at their house at ${location}. They would like ${numOfPeople}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },                           
                            to: this.getNumber('any'),
                        })
                        await(this.confirmUpload(uuidCode));
                    } else {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            numberOfPeople: numOfPeople,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (You've been Requested!)\n${userName} has requested yardwork be done at their house at ${location}. They would like ${numOfPeople}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },     
                            to: this.getNumber(document.getElementById("pledge").value),
                        })
                        await(this.confirmUpload(uuidCode));
                    }
                } catch (e) {
                    alert(e);
                }
                break;   
                case "SweepMop":
                try {
                    let location = document.getElementById('HouseLocation').value
                    let numOfPeople = document.getElementById('HouseNumOfPledges').value
                    let otherDetails = document.getElementById('HouseOtherDetails').value
                    
                    if(prefferedPledge === 'any') {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            numberOfPeople: numOfPeople,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (Any Pledge Available)\n${userName} has requested their house be sweeped/mopped at ${location}. They would like ${numOfPeople}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },                           
                            to: this.getNumber('any'),
                        })
                        await(this.confirmUpload(uuidCode));
                    } else {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            numberOfPeople: numOfPeople,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (Any Pledge Available)\n${userName} has requested their house be sweeped/mopped at ${location}. They would like ${numOfPeople}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },     
                            to: this.getNumber(document.getElementById("pledge").value),
                        })
                        await(this.confirmUpload(uuidCode));
                    }
                } catch (e) {
                    alert(e);
                }
                break;             
            case "SpotAtGym":
                try {
                    let location = document.getElementById('SpotLocation').value
                    let otherDetails = document.getElementById('SpotOtherDetails').value
                    
                    if(prefferedPledge === 'any') {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (Any Pledge Available)\n${userName} has requested they be spotted at the gym at ${location}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },                           
                            to: this.getNumber('any'),
                        })
                        await(this.confirmUpload(uuidCode));
                    } else {
                        await setDoc(doc(db, "messages", uuidCode), {
                            userName: userName,
                            location: location,
                            otherdetails: otherDetails,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `Job Recieved! (You've been Requested!)\n${userName} has requested they be spotted at the gym at ${location}. They have requested for this job be completed ${timeForRequest}. Other Details: ${otherDetails}`
                            },     
                            to: this.getNumber(document.getElementById("pledge").value),
                        })
                    }
                } catch (e) {
                    alert(e);
                }
                break;    
            case "Other":
                try {
                    let otherDetails = document.getElementById('OtherOtherDetails').value
                    let contentText;
                    let sendTo;
                    if(prefferedPledge === 'any') {
                    contentText = `Job Recieved! (Any Pledge Available)\n${userName} has requested a unique job. They have requested for this job be completed ${timeForRequest}. Details: ${otherDetails}`
                    sendTo = this.getNumber('any')
                    } else {
                    contentText = `Job Recieved! (You've been Requested!)\n${userName} has requested a unique job. They have requested for this job be completed ${timeForRequest}. Details: ${otherDetails}`
                    sendTo = this.getNumber(document.getElementById("pledge").value)
                    }

                    if(await this.handleDataRequest(userName, contentText, email, uuidCode)) {

                    //Uploads the message to the database
                    await setDoc(doc(db, "messages", uuidCode), {
                        userName: userName,
                        otherdetails: otherDetails,
                        channelId: '3c046d048582439aa6ec8ec9cd834b48',
                        type: 'text',
                        content: {
                            text: contentText,
                        },                           
                        to: sendTo,
                    })
                    await(this.confirmUpload(uuidCode));
                    } else {
                        alert('Request Failed! Error Code 413');
                    }
                    } catch (e) {
                        alert(e);
                    }
                     break;                      
            case "BootsAndJeansCheck":
                try {
                    let keyPassword = "Blumpkin"
                    let inputPassword = document.getElementById('BootsAndJeansPassword').value
                    let timeToCompleteBy = document.getElementById('BootsAndJeansTime').value

                    if(inputPassword === keyPassword) {
                        await setDoc(doc(db, "messages", uuidCode), {
                            timeToComplete: timeToCompleteBy,
                            channelId: '70842011abbb4cf3b9c83ece6b6c7ba3',
                            type: 'text',
                            content: {
                                text: `🚨 BOOTS AND JEANS CHECK! 🚨\n Submit a photo by ${timeToCompleteBy}`
                            },                           
                            to: this.getNumber('any'),
                        })
                    } else {
                        alert('Incorrect Password');
                    }
                } catch (e) {
                    alert(e);
                }   
                break;                         
        }     
    }
    
    dateConditonals() { 
            return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                label="Time For Request"
                value={this.state.timeForRequest}
                onChange={this.handleTimeChange}
                ampmInClock={true}
                autoFocus={false}
                renderInput={(params) => <TextField {...params} />}        
            />
            </LocalizationProvider>
            )
        /* Defunct
        if(day === 5 || day === 6) { //Friday and Saturday is 6-2:30
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    label="Time For Request"
                    value={this.state.timeForRequest}
                    onChange={this.handleTimeChange}
                    renderInput={(params) => <TextField {...params} />}        
                />
                </LocalizationProvider>
                )
        } 
        */
    } 

    render() {
        return (
        <form onSubmit={this.handleSubmit}>
            <input type="text" id="name" name="name" placeholder="Name..." />
            <select id="selection" onChange={this.myFunc}>
                <option value="default">Select a job...</option>
                <option value="GiveRide">Get a ride</option>
                <option value="BringFood">Get food delivered</option>
                <option value="BringDrinks">Get drinks delivered</option>
                <option value="SweepMop">Get your house sweeped/mopped</option>
                <option value="Yardwork">Get yard work</option>
                {/*<option value="WashCar">Get car washed</option>
                <option value="CookFood">Get food cooked</option>
                <option value="CleanHouse">Get house cleaned</option>
                <option value="SpotAtGym">Get spotted at the gym</option> */}
                {/*<option value="Other">Other</option> */}
                {/* <option value="BootsAndJeansCheck">Boots and Jeans Check (Password Required)</option> */}
            </select>

            <div id="GiveRide" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="RideNumOfPeople" placeholder="How many people..."/> 
                <input type="text" id="RidePickupLocation" placeholder="Pickup location..." />
                <input type="text" id="RideDropoffLocation" placeholder="Dropoff location..." />
                <input type="text" id="RideOtherDetails" placeholder="Other details..."/>
            </div>

            <div id="BringFood" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="FoodResturant" placeholder="Name of Resturant..."/>
                <input type="text" id="FoodOrder" placeholder="Order..."/>
                <input type="text" id="FoodDropoffLocation" placeholder="Dropoff location..."></input>
                <input type="text" id="FoodOtherDetails" placeholder="Other details..."/>
            </div>

            <div id="BringDrinks" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="DrinkPlace" placeholder="Name of Store..."/>
                <input type="text" id="DrinkOrder" placeholder="Order..."/>
                <input type="text" id="DrinkDropoffLocation" placeholder="Dropoff location..."></input>
                <input type="text" id="FoodOtherDetails" placeholder="Other details..."/>
            </div>

            <div id="Yardwork" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="YardLocation" placeholder="Location of house..."/>
                <input type="text" id="YardNumOfPledges" placeholder="Number of Pledges requested..."/>
                <input type="text" id="YardOtherDetails" placeholder="Other details..."/>
            </div>

            <div id="SweepMop" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="HouseLocation" placeholder="Location of house..."/>
                <input type="text" id="HouseNumOfPledges" placeholder="Number of Pledges requested..."/>
                <input type="text" id="HouseOtherDetails" placeholder="Other details..."/>
            </div>

            {/* <div id="WashCar" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="WashLocationOfCar" placeholder="Location of Car..."/>
                <input type="text" id="WashOtherDetails" placeholder="Other details..."/>
            </div>

            <div id="CookFood" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="CookLocationOfFood" placeholder="Location of food being cooked..."/>
                <input type="text" id="CookOtherDetails" placeholder="Other details..."/>
            </div>
            
            <div id="CleanHouse" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="CleanLocation" placeholder="Location of house..."/>
                <input type="text" id="CleanNumOfPledges" placeholder="Number of Pledges requested..."/>
                <input type="text" id="CleanOtherDetails" placeholder="Other details..."/>
            </div>



            
            <div id="SpotAtGym" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="SpotLocation" placeholder="Location of gym..."/>
                <input type="text" id="SpotOtherDetails" placeholder="Other details..."/>
            </div> */}

            <div id="Other" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="OtherOtherDetails" placeholder="Other details..."/>
            </div>

            <div id="Email">
                <input type="text" id="email" placeholder="Email..."/>
            </div>

            {/*<div id="BootsAndJeansCheck" className="block" style={{display: this.state.showStore ? 'block' : 'none'}}>
                <input type="text" id="BootsAndJeansPassword" placeholder="Password..."/>
                <input type="time" id="BootsAndJeansTime"></input>
        </div> */}

            <select id="pledge" style={{display: this.state.showStore ? 'none' : 'block'}} onChange={this.myFunc}>
                <option value="default">Preffered Pledge...</option>
                <option value="any">Any Pledge</option>
            </select>

            {this.dateConditonals()}
            <input type="submit" value={this.state.isLoading ? "Submitting Request..." : "Submit"} disabled={this.state.isLoading} />
        </form>
        )
    }
}

export default JobRequestForm;